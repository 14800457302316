import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ContextConsumer from "src/layouts/Context.js"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"

const StyledLink = styled(Link)`
  font-size: ${typographySizes.xl}px;
  text-decoration: none;
  z-index: 1;
  position: relative;
  text-transform: lowercase;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
  }
`

const StyledExternalLink = styled.a`
  font-size: ${typographySizes.xl}px;
  text-decoration: none;
  z-index: 1;
  position: relative;
  text-transform: lowercase;
  margin: 0 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
  }
`

const Arrow = styled.div`
  position: absolute;
  transform: translate(-200%, -40%);
  /* opacity: 0; */
  left: 50%;
  top: 50%;
  z-index: 0;
  display: flex;
  align-items: center;
  transition: all 0.5s;
`

const ArrowHead = styled.div`
  border: solid ${colors.mainColor};
  border-width: 0 25px 25px 0;
  display: inline-block;
  padding: 25px;
  transform: scale(0) rotate(-45deg);
  transition: all 0.5s 0.2s;
`

const ArrowBody = styled.div`
  height: 25px;
  width: 0px;
  background-color: ${colors.mainColor};
  transform: translate(60px, 0);
  transition: all 0.3s;
`

const Separator = styled.span`
  font-size: ${typographySizes.xl}px;
  margin: 0 20px;
  display: inline-block;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    display: none;
  }
`

const Wrapper = styled.button`
  position: relative;
  z-index: 0;
  &.show ${Arrow} {
    transform: translate(calc(-50% - 55px), -40%);
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33);
    /* opacity: 1; */
  }
  &.show ${ArrowHead} {
    transform: scale(1) rotate(-45deg);
    transition: all 1s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  &.show ${ArrowBody} {
    width: 155px;
    transition: all 1s 0.2s cubic-bezier(0.17, 0.67, 0.03, 1.33);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.l}px;
    width: 100%;
    text-align: left;
  }
`

class CompetencesLink extends React.Component {
  state = { hoverON: false }

  showArrow = () => {
    this.setState({
      hoverON: true,
    })
  }

  hideArrow = () => {
    this.setState({
      hoverON: false,
    })
  }

  render() {
    return (
      <ContextConsumer>
        {({ data, set }) => (
          <Wrapper
            onMouseEnter={this.showArrow}
            onMouseLeave={this.hideArrow}
            className={this.state.hoverON ? "show" : ""}
            onClick={() =>
              set({ competence: this.props.strapiId, sectionViz: false })
            }
          >
            <Arrow>
              <ArrowBody />
              <ArrowHead />
            </Arrow>
            {this.props.external ? (
              <StyledExternalLink target="blank" href={this.props.link}>
                {this.props.children}
              </StyledExternalLink>
            ) : (
              <>
                <StyledLink to={this.props.link + "/"}>
                  {this.props.children}
                </StyledLink>
                <Separator>/</Separator>{" "}
              </>
            )}
          </Wrapper>
        )}
      </ContextConsumer>
    )
  }
}

export default CompetencesLink
